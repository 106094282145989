@import "./assets/styles/scrollbar.css";
@import "./assets/styles/range.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "PPRightGothic";
    src: url("assets/fonts/PPRightGothic-Medium.otf");
}

@font-face {
    font-family: "InterLight";
    src: url("assets/fonts/Inter-Light.ttf");
}

@font-face {
    font-family: "InterNormal";
    src: url("assets/fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: "InterMedium";
    src: url("assets/fonts/Inter-Medium.ttf");
}

@font-face {
    font-family: "InterBold";
    src: url("assets/fonts/Inter-Bold.ttf");
}

@layer base {
    html {
        font-family: InterNormal, system-ui, sans-serif;
    }
}